import React from 'react'
import { useForm } from 'react-hook-form';
import fondo from '../assets/img/letra.jpg';
import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';


export const Registropass = (props) => {
    //Para el setTimeOut 
    const timerRef = useRef(null);
    const token = props.token;

    const { register, formState: { errors }, handleSubmit, watch } = useForm({
        // defaultValues: {
        //     correo: 'julio@outlook.com'
        // }
    });
    const password = watch('password');
    const confirmPassword = watch('confirmpassword');
    const validatePasswords = () => {
    var texterror = '';
    const diverror = document.getElementById('error');
        if (confirmPassword !== password) {
            texterror = 'Las contraseñas no coinciden';
            diverror.innerHTML = texterror;
            diverror.style.display = 'block';
            return false;
        }else{
            diverror.style.display = 'none';
            return true;
        }
        
    };

    const Enviar = (data) => {
        data.bandera = "u1";
        data.token = token;
        console.log(data);
        fetch('https://cims.sppseguros.com.mx/funciones/obtener_info.php', {
            method: 'POST',
            header: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(resp => resp.json())
            .then(json => {
                Swal.fire({
                    icon: 'success',
                    title: '¡GENIAL!',
                    text: 'Gracias por registrarse, favor inicié sesión.',
                })
                console.log('respuesta', json);
                timerRef.current = setTimeout(() => window.location.href = "/", 4000);
            })
    }
    return (
        <div className="hero min-h-screen " style={{ backgroundImage: `url(${fondo})`, backgroundSize: `100%`, backgroundRepeat: "no-repeat", backgroundPosition: "0 90%" }}>
            <div className="hero-overlay bg-opacity-60"></div>
            <div className=" text-center pb-48">
                <div className="card w-screen max-w-sm shadow-2xl bg-base-100">
                    <form className="card-body" onSubmit={handleSubmit(Enviar)}>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Favor de ingresar su nueva contraseña</span>
                            </label>
                            <input type="password" placeholder="Ingrese una contraseña" className="input input-bordered mb-10" {...register('password', { required: true})} />
                            {errors.password?.type === 'required' && <p>La contraseña es requerida</p>}
                            <input type="password" placeholder="Confirme la contraseña" className="input input-bordered" {...register('confirmpassword', { required: true, validate: validatePasswords })} />
                            {errors.confirmpassword && <p>{errors.confirmpassword.message}</p>}
                            <p className='text-sm text-red-500 ml-2' id="error"></p>
                        </div>
                        <div className="form-control mt-6">
                            <input type="submit" value="Registrar" className="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
