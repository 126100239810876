import './index.css';
import {Route, Routes, useLocation} from "react-router-dom";
import { FormInicial } from './components/FormInicial';
import { FormLogin } from './components/FormLogin';
import { Main } from './components/main';
import { Registropass } from './components/Registrarpass';
// import {useEffect, useState} from 'react';

function App() {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  return (
    <>
    <Routes>
      <Route path="/" element={token ? <Registropass token={token}/> : <FormLogin/>}/>
      <Route path="VerificarCorreo" element={<FormInicial/>}/>
      <Route path="inicio" element={<Main/>}/>
    </Routes>
    </>
  );
}

export default App;
