import { React,useEffect } from 'react'
import { useForm } from 'react-hook-form';
import fondo from '../assets/img/letra.jpg';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export const FormLogin = () => {

    const { register, formState: { errors }, handleSubmit } = useForm({
        // defaultValues: {
        //     correo: 'julio@outlook.com'
        // }
    });
    useEffect(() => {
        window.localStorage.clear();
    })

    const navigate = useNavigate();
    
    const Enviar = (data) => {
        data.bandera = "s3";
        console.log(data);
        fetch('https://cims.sppseguros.com.mx/funciones/obtener_info.php', {
            method: 'POST',
            header: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(resp => resp.json())
            .then(json => {
                console.log(json);
                const id = json[0][0];
                const correo = json[0][1];
                const password = json[0][2];
                const vacio = json[0]['variable'];
                if (vacio !== 'undefined') {
                    if (data.RFC === correo) {
                        if (data.password === password) {
                            navigate('inicio',{
                                state: { id : id}
                            });
                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: 'La contraseña que escribió no es válida.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'El RFC escrito no está registrado, favor de revisarlo.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'El correo escrito no está registrado, favor de revisarlo.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }

    return (
        <div className="hero min-h-screen " style={{ backgroundImage: `url(${fondo})`, backgroundSize: `100%`, backgroundRepeat: "no-repeat", backgroundPosition: "0 90%" }}>
            <div className="hero-overlay bg-opacity-60"></div>
            <div className=" text-center pb-48">
                <div className="card w-screen max-w-sm shadow-2xl bg-base-100">
                    <form className="card-body" onSubmit={handleSubmit(Enviar)}>
                    <h1>INICIO DE SESIÓN</h1>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">RFC</span>
                            </label>
                            <input type="text" placeholder="Ingrese su RFC" className={`input input-bordered input-sm focus:ring-blue-500 focus:border-blue-500 w-full block p-2.5 ${errors.RFC && "focus:border-red-500 focus:ring-red-500"}`} {...register('RFC', { required: 'El RFC es requerido', /*pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i*/ })} />
                            <div> {errors.RFC && <p className='text-sm text-red-500 ml-2'>{errors.RFC.message}</p>}</div>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Contraseña</span>
                            </label>
                            <input type="password" placeholder="Ingrese la Contraseña" className={`input input-bordered input-sm focus:ring-blue-500 focus:border-blue-500 w-full block p-2.5 ${errors.password && "focus:border-red-500 focus:ring-red-500"}`} {...register('password', { required: 'La contraseña es requerida' })} />
                            <div> {errors.password && <p className='text-sm text-red-500 ml-2'>{errors.password.message}</p>}</div>
                            <label className="label">
                                <Link to="VerificarCorreo?dato=1" className="label-text-alt link link-hover">Ingresar por primera vez</Link>
                            </label>
                            <label className="label">
                                <Link to="VerificarCorreo?dato=2" className="label-text-alt link link-hover">¿Se te olvido la contraseña?</Link>
                            </label>
                        </div>
                        <div className="form-control mt-6">
                            <input type="submit" value="Iniciar sesión" className="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
