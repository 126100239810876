import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import fondo from '../assets/img/letra.jpg';
import { Menu } from './Menu';
import Swal from 'sweetalert2';
import { useLocalStorage } from './useLocalStorage';

export const Main = () => {

    const [mostrarComponente, setMostrarComponente] = useState(true);

    // const Texto = (props) =>{
    //     console.log(props);
    // }
    const { state } = useLocation();
    const idcliente = state.id;
    const [text, setText] = useLocalStorage('text', '');
    // setText(idcliente);

    // console.log(idcliente);

    const [isLoading, setIsLoading] = useState(true);
    const [Desarrollador, setDesarollador] = useState();
    const [Rfc, setRfc] = useState();
    const [Calle, setCalle] = useState();
    const [num_int, setnum_int] = useState();
    const [num_ext, setnum_ext] = useState();
    const [CP, setCP] = useState();
    const [Colonia, setColonia] = useState();
    const [Estado, setEstado] = useState();
    const [Ciudad, setCiudad] = useState();
    const [Municipio, setMunicipio] = useState();
    const [IdCol, setIdCol] = useState();

    //Este es la variable del Id de la colonia que se mandara actomaticamente y actualice los datos
    const [IdColAuto, setIdColAuto] = useState();

    if(mostrarComponente == false){        
        // console.log(IdCol);
        fetch('https://cims.sppseguros.com.mx/funciones/obtener_info.php', {
            method: 'POST',
            header: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify({ RFC: IdCol, bandera: 's6' }),
        })
            .then(resp => resp.json())
            .then(json => {
                setIdColAuto(json['id']);
                document.getElementById('id_col').innerHTML = json['option'];
            });
    }
    // Funcion para obtener la Colonia
    function handleChange(event) {
        let cp = event.target.value;
        // console.log(cp);
        fetch('https://cims.sppseguros.com.mx/funciones/obtener_info.php', {
            method: 'POST',
            header: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify({ cp: cp, bandera: 's5' }),
        })
            .then(resp => resp.json())
            .then(json => {
                document.getElementById('municipio').value = json['municipio'];
                document.getElementById('estado').value = json['edo'];
                document.getElementById('id_col').innerHTML = json['col'];
            });
    }

    // funcion pra extraer los datos del usuario
    useEffect(() => {
        fetch('https://cims.sppseguros.com.mx/funciones/obtener_info.php', {
            method: 'POST',
            header: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify({ id: idcliente, bandera: 's4' })
        })
            .then(resp => resp.json())
            .then(json => {
                console.log(json);
                setDesarollador(json[0][1]);
                setRfc(json[0][2]);
                setCalle(json[0][4]);
                setnum_int(json[0][5]);
                setnum_ext(json[0][6]);
                setCP(json[0][7]);
                setColonia(json[0][8]);
                setEstado(json[0][9]);
                setCiudad(json[0][10]);
                setMunicipio(json[0][11]);
                setIdCol(json[0][12]);
                setIsLoading(false);
            });
    }, []);

    // Son variables que se ocupan de useForm y ademas puedes poner cosas estaticas en el formulario
    const { register, formState: { errors }, handleSubmit, getValues } = useForm({
        // defaultValues: {
        //     cp: ''
        // }
    });

    

    // Envio de los datos del formulario
    const Enviar = async (data) => {
        let formData = new FormData();
        formData.append('bandera', 'u2');
        formData.append('idcliente', idcliente);
        formData.append('rfc', Rfc);
        formData.append('calle', data.Calle);
        formData.append('cp', data.cp);
        formData.append('idcol', data.idcol ? data.idcol : IdColAuto);
        formData.append('numint', data.numint);
        formData.append('numext', data.numext);
        // formData.append('idusr', '1100');
        formData.append('fiscal', data.fiscal[0]);
        formData.append('domicilio', data.domicilio[0]);
        // console.log(data.idcol);
        for(let [name, value] of formData) {
            console.log(`${name} = ${value}`);
        }

        const res = await fetch('https://cims.sppseguros.com.mx/funciones/obtener_info.php', {
            method: 'POST',
            body: formData
        })
            .then((res) => res.text())
            .then(json => {
                console.log(json);
                if (json === 'Exito' || json === 'ExitoExito' || json === 'ExitoExitoExito') {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Sus datos se han actualizado con éxito.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setInterval("location.reload()",1500);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Lo sentimos hubo un error en sus datos de actualización, favor de intentar de nuevo',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    // setInterval("location.reload()",1500);
                }
            })
    }

    // Pagina de carga cuando no tiene los datos aun
    if (isLoading) {
        return (
            <div>
                <Menu />
                <div className="container mx-auto pt-10 ">
                    <div className="card lg:card-side bg-base-100 drop-shadow-[35px_35px_35px_rgba(0,0,0,0.25)] border-solid border-2 border-sky-500">
                        <div className="card-body">
                            <div className="flex justify-center">
                                <h1 className="card-title text-center">Datos del usuario</h1>
                            </div>
                            <p className="text-center">Bienvenido: {Desarrollador}</p>
                            <form>
                                <h1>CARGANDO...</h1>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <Menu />
            <div className="container mx-auto pt-10 ">
                <div className="card lg:card-side bg-base-100 drop-shadow-[35px_35px_35px_rgba(0,0,0,0.25)] border-solid border-2 border-sky-500">
                    <div className="card-body">
                        <div className="flex justify-center">
                            <h1 className="card-title text-center">Datos del usuario</h1>
                        </div>
                        <p className="text-center">Bienvenido: {Desarrollador}</p>
                        <form onSubmit={handleSubmit(Enviar)}>
                            <div className="form-control grid gap-6 mb-6 md:grid-cols-2">
                                <label className="input-group pt-10">
                                    <span>RFC</span>
                                    <input type="text" defaultValue={Rfc} className="input input-bordered input-sm text-sm" disabled />
                                </label>
                                <label className="input-group pt-10">
                                    <span>Calle</span>
                                    {mostrarComponente ?
                                        <input type="text" defaultValue={Calle} className="input input-bordered input-sm text-sm w-full" disabled />
                                        : <input type="text" {...register('Calle', { required: 'La calle es requerida' })} className={`input input-bordered input-sm focus:ring-blue-500 focus:border-blue-500 w-full block p-2.5 ${errors.Calle && "focus:border-red-500 focus:ring-red-500"}`} />
                                    }
                                    <div> {errors.Calle && <p className='text-sm text-red-500 ml-2'>{errors.Calle.message}</p>}</div>
                                </label>
                                <label className="input-group pt-10">
                                    <span>No. Interior</span>
                                    {mostrarComponente ?
                                        <input type="text" defaultValue={num_int} className="input input-bordered w-100 input-sm" disabled />
                                        : <input type="text" {...register('numint')} className="input input-bordered input-sm focus:ring-blue-500 focus:border-blue-500 block p-2.5" />
                                    }
                                </label>
                                <label className="input-group pt-10">
                                    <span>No. Exterior</span>
                                    {mostrarComponente ?
                                        <input type="text" defaultValue={num_ext} className="input input-bordered w-100 input-sm" disabled />
                                        : <input type="text" {...register('numext', { required: 'El número exterior es requerido' })} className={`input input-bordered input-sm focus:ring-blue-500 focus:border-blue-500 block p-2.5 ${errors.numext && "focus:border-red-500 focus:ring-red-500"}`} />
                                    }
                                    <div> {errors.numext && <p className='text-sm text-red-500 ml-2'>{errors.numext.message}</p>}</div>
                                </label>
                                <label className="input-group pt-10">
                                    <span>Código Postal:</span>
                                    {mostrarComponente ?
                                        <input type="text" defaultValue={CP} className="input input-bordered input-sm" disabled />
                                        : <input type="text" defaultValue='' {...register('cp', { required: 'Código postal es requerido' })} className={`input input-bordered input-sm focus:ring-blue-500 focus:border-blue-500 block p-2.5 ${errors.cp && "focus:border-red-500 focus:ring-red-500"}`} onChange={handleChange} />
                                    }
                                    <div> {errors.cp && <p className='text-sm text-red-500 ml-2'>{errors.cp.message}</p>}</div>
                                </label>
                                <label className="input-group pt-10">
                                    <span>Colonia:</span>
                                    {mostrarComponente ?
                                        <input type="text" defaultValue={Colonia} className="input input-bordered input-sm " disabled />
                                        :
                                        <select id="id_col"  {...register("idcol")}>
                                            {/* {...register("idcol", { required: true })} */}
                                            
                                        </select>
                                    }
                                </label>
                                <label className="input-group pt-10">
                                    <span className="input-sm">Estado:</span>
                                    {
                                        <input id="estado" type="text" defaultValue={Estado} className="input input-bordered input-sm" disabled />

                                    }
                                </label>
                                <label className="input-group pt-10">
                                    <span>Ciudad:</span>
                                    {
                                        <input type="text" defaultValue={Ciudad} className="input input-bordered input-sm" disabled />

                                    }
                                </label>
                                <label className="input-group pt-10">
                                    <span>Municipio:</span>
                                    {
                                        <input id="municipio" type="text" defaultValue={Municipio} className="input input-bordered input-sm" disabled />
                                    }
                                </label>
                            </div>

                            <div className="flex justify-between pt-10 pl-28">
                                <div className="card w-100 bg-base-100 shadow-xl">
                                    <div className="card-body">
                                        <h2 className="text-center">Comprobante domicilio.</h2>
                                        {mostrarComponente ?
                                            null
                                            : <input type="file" accept="application/pdf" {...register("domicilio")} className="file-input file-input-bordered file-input-primary w-full max-w-xs show-element" />
                                        }
                                    </div>
                                </div>
                                <div className="card w-100 bg-base-100 shadow-xl mr-32">
                                    <div className="card-body">
                                        <h2 className="text-center">Constancia de situación fiscal.</h2>
                                        {mostrarComponente ?
                                            null
                                            : <input type="file" accept="application/pdf" {...register("fiscal")} className="file-input file-input-bordered file-input-primary w-full max-w-xs show-element" />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="card-actions justify-end">
                                {mostrarComponente ?
                                    <button className="btn btn-ghost mt-10" onClick={() => setMostrarComponente(!mostrarComponente)} >Editar Información</button>
                                    :
                                    <div className='mt-10'>
                                        <button type="submit" className="btn btn-success mr-10">Guardar Información</button>
                                        <button className="btn btn-error" onClick={() => setMostrarComponente(!mostrarComponente)}>Cancelar</button>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
