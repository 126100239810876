import React from 'react';
import icon from '../assets/img/icon.png';
import { useNavigate } from 'react-router-dom';

export const Menu = () => {

    const navigate = useNavigate();

    const cerrar = () => {
        localStorage.clear();
        navigate('/');
    }

    return (
        <div className="navbar text-base-100 bg-primary">
            <div className="navbar-start">
            </div>
            <div className="navbar-center lg:flex">
                <a className="btn btn-ghost normal-case text-xl"><img src={icon} alt="icono de SPPIS" /></a>
            </div>
            <div className="navbar-end">
                <a className="btn" onClick={cerrar}>Cerrar Sesión</a>
                {/* <Link to="/" className="btn">Cerrar Sesion</Link> */}
            </div>
        </div>

    )
}
