import React, { useRef } from 'react'
import { useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import fondo from '../assets/img/letra.jpg';
import Swal from 'sweetalert2';


export const FormInicial = () => {
    const query = new URLSearchParams(useLocation().search);
    const variable = query.get('dato');
    //Para el setTimeOut
    const timerRef = useRef(null);

    const { register, formState: { errors }, handleSubmit } = useForm({
        // defaultValues: {
        //     correo: 'julio@outlook.com'
        // }
    });

    const Enviar = (data) => {
        data.bandera = "s1";
        let correo = data["correo"], respcorreo, activo;
        // console.log(data);
        fetch('https://cims.sppseguros.com.mx/funciones/obtener_info.php', {
            method: 'POST',
            header: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(resp => resp.json())
            .then(json => {
                if (json[0] != null) {
                    respcorreo = json[0][0];
                    activo = json[0][1];
                    if (variable == '1') {
                        data.bandera = "s2";
                        var texto = 'Favor de revisar el correo electronico ingresado y seguir los pasos para su registro en la plataforma';
                    } else if (variable == '2') {
                        if (activo == 0) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Lo sentimos...',
                                text: 'Pero usted no ha registrado ninguna contraseña, por favor antes de cambiarla registré una contraseña nueva',
                                showConfirmButton: false,
                                footer: '<a class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" href="VerificarCorreo?dato=1">Registrar contraseña por primera vez</a>'
                            })
                            return false;
                        } else {
                            data.bandera = "s7"
                            var texto = 'Favor de revisar el correo electronico ingresado y seguir los pasos para la actualización de la contraseña';
                            activo = 0;
                        }
                    } if (activo == 0) {
                        if (respcorreo == correo) {
                            Swal.fire({
                                icon: 'success',
                                title: '¡GENIAL!',
                                text: texto,
                            })
                            fetch('https://cims.sppseguros.com.mx/funciones/obtener_info.php', {
                                method: 'POST',
                                header: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                                body: JSON.stringify(data)
                            })
                                .then(resp => resp.json())
                            // .then(json => {
                            //     console.log('respuesta: ', json);
                            // })
                            timerRef.current = setTimeout(() => window.location.href = "/", 4000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Lo sentimos...',
                                text: 'Hubo un problema con los datos mandados, favor de ponerse en contacto con sistemas.',
                            })
                        }
                    } else if (variable == 1) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Lo sentimos...',
                            text: 'Pero el correo con el que intenta ingresar ya fue verificado, favor de solo iniciar sesión.',
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos...',
                        text: 'El correo que ingreso no se encuentra en nuestro sistema, favor de intentar con otro.',
                    })
                }
            })

    }
    switch (variable) {
        case '1':
            return (
                <div className="hero min-h-screen " style={{ backgroundImage: `url(${fondo})`, backgroundSize: `100%`, backgroundRepeat: "no-repeat", backgroundPosition: "0 90%" }}>
                    <div className="hero-overlay bg-opacity-60"></div>
                    <div className=" text-center pb-48">
                        <div className="card w-screen max-w-sm shadow-2xl bg-base-100">
                            <form className="card-body" onSubmit={handleSubmit(Enviar)}>
                                <h1>Generar contraseña nueva.</h1>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Favor de ingresar el correo que está dado de alta con nosotros, ahí se les mandara un correo con las instrucciones.</span>
                                    </label>
                                    <input type="text" placeholder="Ingrese el correo" className="input input-bordered" {...register('correo', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i })} />
                                    {errors.correo?.type === 'pattern' && <p>El formato del correo es incorrecto</p>}
                                    {errors.correo?.type === 'required' && <p>Favor de escribir un correo</p>}
                                </div>
                                <div className="form-control mt-6">
                                    <input type="submit" value="Mandar" className="btn btn-primary" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
            break;
        case '2':
            return (
                <div className="hero min-h-screen " style={{ backgroundImage: `url(${fondo})`, backgroundSize: `100%`, backgroundRepeat: "no-repeat", backgroundPosition: "0 90%" }}>
                    <div className="hero-overlay bg-opacity-60"></div>
                    <div className=" text-center pb-48">
                        <div className="card w-screen max-w-sm shadow-2xl bg-base-100">
                            <form className="card-body" onSubmit={handleSubmit(Enviar)}>
                                <h1>Cambiar la contraseña anterior.</h1>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Favor de ingresar el correo que está dado de alta con nosotros, ahí se les mandara un correo con las instrucciones.</span>
                                    </label>
                                    <input type="text" placeholder="Ingrese el correo" className="input input-bordered" {...register('correo', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i })} />
                                    {errors.correo?.type === 'pattern' && <p>El formato del correo es incorrecto</p>}
                                    {errors.correo?.type === 'required' && <p>Favor de escribir un correo</p>}
                                </div>
                                <div className="form-control mt-6">
                                    <input type="submit" value="Mandar" className="btn btn-primary" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
            break;

        default:
            break;
    }

}
